import { DataProtectionPageComponent } from './pages/data-protection-page/data-protection-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImprintPageComponent } from './pages/imprint-page/imprint-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { TermsAndConditionsPageComponent } from './pages/terms-and-conditions-page/terms-and-conditions-page.component';
import { ShortPageComponent } from './pages/short-page/short-page.component';

const routes: Routes = [
  {
    path: '',
    component: ShortPageComponent,
  },
  {
    path: 'terms',
    component: TermsAndConditionsPageComponent,
  },
  {
    path: 'imprint',
    component: ImprintPageComponent,
  },
  {
    path: 'data-policy',
    component: DataProtectionPageComponent,
  },
  {
    //   path: 'shooting',
    //   component: MainPageComponent
    // }, {
    path: '**',
    component: ShortPageComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
